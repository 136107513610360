import clsx from "clsx";
import {useState} from "react";
import {DRAWER_WIDTH} from "config/constants";
import CustomDrawer from "./components/CustomDrawer";
import CustomAppBar from "./components/CustomAppBar";
import makeStyles from "@mui/styles/makeStyles";
import {useDispatch} from "react-redux";
import {logOut} from "redux/slices/authSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },

    content: {
        width: '100%',
        padding: theme.spacing(3),
        overflow: "clip",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        marginLeft: -DRAWER_WIDTH,
    },
    contentShift: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const storedDrawerValue = localStorage.getItem('isDrawerOpen') ? localStorage.getItem('isDrawerOpen') === 'true' : true

const AdminLayout = ({children, history}) => {
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(storedDrawerValue);
    const dispatch = useDispatch();
    const handleDrawerToggle = () => {
        setIsDrawerOpen(prevIsDrawerOpen => {
            localStorage.setItem('isDrawerOpen', !prevIsDrawerOpen)
            return !prevIsDrawerOpen
        });
    };

    const handleLogout = () => {
        dispatch(logOut());
        history.push("/");
    };

    return (
        <div className={classes.root}>
            <CustomAppBar
                history={history}
                isDrawerOpen={isDrawerOpen}
                handleLogout={handleLogout}
                handleDrawerToggle={handleDrawerToggle}
            />
            <CustomDrawer
                history={history}
                isDrawerOpen={isDrawerOpen}
                handleDrawerToggle={handleDrawerToggle}
            />
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: isDrawerOpen,
                })}
            >
                <div className={classes.toolbar} style={{minHeight: "37px"}}/>
                {children}
            </main>
        </div>
    );
};

export default AdminLayout;
