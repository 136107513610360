// Background colors
const backgroundColor = "#ebeef5";
const backgroundPaperColor = "#FFFFFF";
const darkBlueColor = "#1E7597";
const darkBlueColorContrast = "#128BBA";
const whiteColor = "#fcfcfcff";
const lightWhiteColor = "#ffffff54";
const offWhiteColor = "#dee1e621";

// Button colors
const blueColor = "#006189";
const blueAccentColor = "#50a1e8";
const lightBlueColor = "rgba(0, 97, 137, 0.2)";

// Text color
const blackColor = "#2b3b4c";
const greyColor = "#52575C";
const lightGreyColor = "#e0e0e0";
const lighterGreyColor = "#ecf3ff44";
const greyBgFieldColor = "#fcfcfcb8";

// Action colors
const redColor = "#c30000cc";
const lightRedColor = "#FF6760";

const yellowColor = "#e1b412";
const brownColor = "#EEA21F";
const greenColor = "#10d850";
const lemonGreenColor = "#7AC16C";
const lighterGrayColor = "#f2f2f2";
const orangeColor = "#ffeecc";
const cyanColor = "#9ee6ff4a";
const babyBlueColor = "#dfe7fa";
const pinkColor = "#ffd7d3";
const darkBlue = "#305466";
const goldColor = "#ffd700";
const silverColor = "#c0c0c0";
// Branding Colors
const brandingGreen = "#4b883f";
const brandingRed = "#820F30";

// Tooltip
const tooltip = "#c9c7c79d";

//Tables
const tableRowColor = "#c5c5c534";
//Fields
const fieldColor = "rgba(130, 144, 190, 0.082)";
const fieldDisabledColor = "#8aa0af";
//
const ThemeColors = {
  tooltip,
  redColor,
  greyColor,
  blueColor,
  goldColor,
  cyanColor,
  pinkColor,
  whiteColor,
  lightWhiteColor,
  blackColor,
  greenColor,
  lemonGreenColor,
  darkBlue,
  fieldColor,
  fieldDisabledColor,
  orangeColor,
  brandingRed,
  silverColor,
  yellowColor,
  brownColor,
  brandingGreen,
  tableRowColor,
  lightRedColor,
  darkBlueColor,
  babyBlueColor,
  offWhiteColor,
  lighterGrayColor,
  lightGreyColor,
  lightBlueColor,
  backgroundColor,
  blueAccentColor,
  lighterGreyColor,
  greyBgFieldColor,
  backgroundPaperColor,
  darkBlueColorContrast,
};

export default ThemeColors;
