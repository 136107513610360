// General constants
export const MAIN_URL = "/home";
export const COMPANY_NAME = "Origin Systems";

export const REQUEST_TIMEOUT = 15 * 60 * 1000; // 10 mints
export const REQUEST_TIMEOUT_REPORTS = 120 * 1000; // 2 mints
export const INVALID_TOKEN_STATUS_CODE = "820";

// Ui constants
export const DRAWER_WIDTH = 270;
export const TOAST_TIMEOUT = 4 * 1000; // 4 secs
