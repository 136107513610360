import permissions from "./permissions";
import BlockIcon from "@mui/icons-material/Block";
import HistoryIcon from "@mui/icons-material/History";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/Functions";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DataSaverOnOutlinedIcon from "@mui/icons-material/DataSaverOnOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

export const MenuItems = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <HomeOutlinedIcon />,
    url: "/home",
    permissions: [
      permissions.ViewDashboardStatistics,
      permissions.ViewProductChartsReport,
    ],
  },
  {
    id: "point-of-sale",
    title: "Point of Sale",
    type: "item",
    icon: <CreditCardIcon />,
    url: "/point-of-sale",
    permissions: [permissions.POS_Sell],
  },

  {
    id: "verify-status",
    title: "Verify Status",
    type: "item",
    icon: <AssignmentTurnedInOutlinedIcon />,
    url: "/verify-status",
    permissions: [permissions.Verify_Status],
  },
  {
    id: "verification",
    title: "Verification",
    type: "item",
    icon: <AccountTreeOutlinedIcon />,
    url: "/verification",
    permissions: [permissions.ItemVerification],
  },
  {
    id: "decommision",
    title: "Decommission",
    type: "item",
    icon: <BlockIcon />,
    url: "/decommission",
    permissions: [permissions.DecomissionView],
  },
  {
    id: "activate",
    title: "Activate",
    type: "item",
    icon: <FlipCameraAndroidIcon />,
    url: "/activate",
    permissions: [permissions.Activate],
  },
  {
    id: "pack",
    title: "Pack",
    type: "item",
    icon: <DataSaverOnOutlinedIcon />,
    url: "/pack",
    permissions: [permissions.PackView],
  },
  {
    id: "unpack",
    title: "Unpack",
    type: "item",
    icon: <AutoAwesomeMosaicOutlinedIcon />,
    url: "/unpack",
    permissions: [permissions.Disaggregation],
  },

  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "item",
  //   icon: <AutoAwesomeMosaicOutlinedIcon />,
  //   url: "/dashboard",
  //   permissions: [
  //     permissions.ViewDashboardStatistics,
  //     permissions.ViewProductChartsReport,
  //   ],
  // },

  {
    id: "orders",
    title: "Logistic Operations",
    type: "collapse",
    icon: <DescriptionOutlinedIcon />,
    permissions: [
      permissions.SO_View,
      permissions.InboundView,
      permissions.OutboundView,
      permissions.InboundFileView,
      permissions.CycleCount_View,
    ],
    children: [
      {
        type: "item",
        title: "Inbound",
        id: "inbound-orders",
        url: "/inbound-orders",
        icon: <CallReceivedIcon />,
        permissions: [permissions.InboundView],
      },
      {
        type: "item",
        title: "Move",
        id: "move-orders",
        url: "/move-orders",
        icon: <Inventory2OutlinedIcon />,
        permissions: [permissions.MO_View],
      },
      {
        id: "outbound-orders",
        title: "Outbound",
        type: "item",
        icon: <CallMadeIcon />,
        url: "/outbound-orders",
        permissions: [permissions.OutboundView],
      },

      {
        id: "serialization",
        title: "Serialization",
        type: "item",
        icon: <QrCodeRoundedIcon />,
        url: "/serialization",
        permissions: [permissions.SO_View],
      },

      {
        id: "files",
        title: "Shipment Files",
        type: "item",
        icon: <DescriptionOutlinedIcon />,
        url: "/files",
        permissions: [permissions.InboundFileView],
      },
      {
        id: "cycle-count",
        title: "Cycle Count",
        type: "item",
        icon: <PollOutlinedIcon />,
        url: "/cycle-count",
        permissions: [permissions.CycleCount_View],
      },
    ],
  },

  {
    id: "reports",
    title: "Reports",
    type: "collapse",
    icon: <AssessmentOutlinedIcon />,
    permissions: [
      permissions.ViewProductQuantityReport,
      permissions.ViewProductBySupplierReport,
      permissions.ViewProductAvailabilityReport,
    ],
    children: [
      {
        id: "history-report",
        title: "History",
        type: "collapse",
        icon: <HistoryIcon />,

        permissions: [permissions.ViewProductAvailabilityReport],
        children: [
          {
            id: "auditTrail-report",
            title: "Audit Trail",
            type: "item",
            url: "/auditTrail-report",
            permissions: [permissions.ViewProductAvailabilityReport],
          },
        ],
      },
      {
        id: "operation-report",
        title: "Operations",
        type: "collapse",
        icon: <PsychologyOutlinedIcon />,

        permissions: [permissions.ViewProductAvailabilityReport],
        children: [
          {
            id: "availability-report",
            title: "Item Availability",
            type: "item",
            url: "/availability-report",
            // icon: <FindInPageOutlinedIcon />,
            permissions: [permissions.ViewProductAvailabilityReport],
          },
          {
            id: "supplier-report",
            title: "Search By Supplier",
            type: "item",
            url: "/supplier-report",
            // icon: <SearchOutlinedIcon />,
            permissions: [permissions.ViewProductBySupplierReport],
          },
          {
            id: "quantity-report",
            title: "Inventory",
            type: "item",
            url: "/quantity-report",
            // icon: <HomeWorkOutlinedIcon />,
            permissions: [permissions.ViewProductQuantityReport],
          },
          // {
          //   id: "batch-availability",
          //   title: "Batch Availability",
          //   type: "item",
          //   url: "/batch-availability",
          //   // icon: <HomeWorkOutlinedIcon />,
          //   //TODO
          //   permissions: [permissions.ViewProductQuantityReport],
          // },
          {
            id: "Non-serialized",
            title: "Non-Serialized",
            type: "item",
            url: "/non-serialized",
            // icon: <HomeWorkOutlinedIcon />,
            //TODO
            permissions: [permissions.ViewProductQuantityReport],
          },
          {
            id: "decanting-report",
            title: "Decanting Report",
            type: "item",
            url: "/decanting-report",
            // icon: <HomeWorkOutlinedIcon />,
            //TODO
            permissions: [permissions.ViewProductQuantityReport],
          },
        ],
      },
    ],
  },

  {
    id: "administration",
    title: "Administration",
    type: "title",
    permissions: [
      permissions.PartnerView,
      permissions.ProductView,
      permissions.UserManagement,
    ],
    children: [
      {
        id: "permit-number",
        title: "Permit Number",
        type: "item",
        icon: <ConfirmationNumberOutlinedIcon />,
        url: "/permit-number",
        // permissions: [permissions.Machine_View],
      },
      {
        id: "machines",
        title: "Machines",
        type: "item",
        icon: <PrecisionManufacturingOutlinedIcon />,
        url: "/machines",
        permissions: [permissions.Machine_View],
      },
      {
        id: "partners",
        title: "Partners",
        type: "item",
        icon: <HandshakeOutlinedIcon />,
        url: "/partners",
        permissions: [permissions.PartnerView],
      },
      {
        id: "admin-panel",
        title: "Admin Panel",
        type: "item",
        icon: <ManageAccountsOutlinedIcon />,
        url: "/admin-panel",
        permissions: [permissions.PartnerView],
      },

      {
        id: "products",
        title: "Products",
        type: "collapse",
        icon: <MedicationOutlinedIcon />,
        children: [
          {
            id: "products",
            title: "Search by product",
            type: "item",
            url: "/products",
            permissions: [permissions.ProductView],
          },
          {
            id: "skus",
            title: "SKUs",
            type: "item",
            url: "/skus",
          },
          {
            id: "mapping",
            title: "Mapping",
            type: "item",
            url: "/mapping",
          },
        ],
      },

      {
        id: "operators",
        title: "Access Control",
        type: "collapse",
        icon: <PeopleOutlinedIcon />,
        permissions: [permissions.UserManagement],
        children: [
          {
            id: "roles",
            title: "Roles",
            type: "item",
            url: "/roles",
            permissions: [permissions.UserManagement],
          },
          {
            id: "users",
            title: "Users",
            type: "item",
            url: "/users",
            permissions: [permissions.UserManagement],
          },
        ],
      },
    ],
  },
];
