import { useState } from "react";
import { Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {COMPANY_NAME} from "../config/constants";
import originLogo from "../config/assets/origin-original-logo.png";

const CustomImage = ({ src, style, className, alt = "" }) => {
  const [imageError, setImageError] = useState(src == null);

  const onImageError = () => {
    setImageError(true);
  };

  return (
    <>
      {imageError ? (
        <Box
          className={
            "d-flex justify-content-center align-items-center " + className
          }
          style={style}
        >
          <Box
              component='img'
              alt={COMPANY_NAME}
              sx={{
                width: {xs: '45%'},
              }}
              src={originLogo}
          />
          {/*<ErrorOutlineIcon color="error" />*/}
        </Box>
      ) : (
        <img
          src={src}
          alt={alt}
          className={className}
          style={style}
          onError={onImageError}
        />
      )}
    </>
  );
};

export default CustomImage;
