import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomImage from "components/CustomImage";
import { DRAWER_WIDTH } from "config/constants";
import { Box, Divider } from "@mui/material/";

const CustomDrawerHeader = () => {
  const logo = useSelector((state) => state?.auth?.user?.logo);
  return (
    <Link to="/" className="d-flex align-items-center justify-content-center">
      <CustomImage
        src={logo}
        alt="Home"
        className="p-2"
        style={{
          width: DRAWER_WIDTH - 20,
          height: 71,
          objectFit: "scale-down",
        }}
      />
    </Link>
  );
};

export default CustomDrawerHeader;
