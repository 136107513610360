import clsx from "clsx";
import AppBarUserIcon from "./AppBarUserIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { DRAWER_WIDTH } from "config/constants";
import { AppBar, IconButton, Toolbar } from "@mui/material/";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.background.default,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const CustomAppBar = ({
  history,
  isDrawerOpen,
  handleLogout,
  handleDrawerToggle,
}) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
      })}
    >
      <Toolbar className="justify-content-between">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={clsx(classes.menuButton)}
          size="large">
          <MenuIcon color="primary" />
        </IconButton>
        <AppBarUserIcon history={history} handleLogout={handleLogout} />
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
